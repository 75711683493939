import React, { useState } from 'react';

const HeaderPage = ({ headerTxt }) => {
    return (
        <>
            <div className='container pt-5 ps-0'>
                <div className='row justify-content-center'>
                    <div className='col-md-12'>
                        <div className='mb-5'>
                            <h1 className='display-5 text-left fw-bold'>{ headerTxt }</h1>
                        </div>
                    </div>
                </div>
            </div>        
      </>
    );
};

export default HeaderPage;